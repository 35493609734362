<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Download bearbeiten">
      <download-form
        :loading="loading"
        :download="download"
        :form-submitted="saveDownload"
        mode="edit"
      ></download-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import DownloadForm from "@/components/forms/DownloadForm"
import DownloadsRepository from "@/repositories/downloads_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Download bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    DownloadForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      download: {
        description: ""
      }
    }
  },
  async created() {
    try {
      this.download = await DownloadsRepository.get(this.$route.params.id)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveDownload(download) {
      await DownloadsRepository.update(download.id, download)

      try {
        await this.$router.push({
          name: "DownloadsPage",
          query: { allow_redirect: true }
        })
      } catch {
        //
      }
    }
  }
}
</script>
